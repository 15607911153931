import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Rule } from '../aggregated-rules/aggregated-rules.component';
import { JsonfileService } from '../aggregated-rules/services/jsonfile.service';

@Component({
  selector: 'app-aggregated-rules-details',
  templateUrl: './aggregated-rules-details.component.html',
  styleUrls: ['./aggregated-rules-details.component.css']
})
export class AggregatedRulesDetailsComponent implements OnInit {
  selectedAnnotation: any;
  annotationData: any
  ifImageSource: string | undefined;
  thenImageSource: string | undefined;
  andImageSource: string | undefined;
  andAnyImageSource: string | undefined;
  selectedRule!: Rule;
  NotLastCondition = true;
  annotation;
  conditions = new Array();
  rule;
  chartData;

  constructor(private activatedRoute: ActivatedRoute, public jsonfileServive: JsonfileService) {
    this.selectedAnnotation = this.jsonfileServive.selectedAnnotation;
    this.annotation = this.activatedRoute.snapshot.paramMap.get('Annotation');

    /*
    this.jsonfileServive.loadAnnotationDetails(this.activatedRoute.snapshot.paramMap.get('Annotation'),"LaPoste")
    .subscribe(value => {
      console.log("value:",value);
      this.selectedRule = value;
      this.rule = this.selectedRule.Rules;
    });*/

    this.jsonfileServive.getChartData(this.jsonfileServive.curentProjectId).subscribe((alldata: any) => {
      this.getAnnotationDetails(alldata["chartsData"][0]["children"][0]["children"]);
      console.log("annotationData:", this.annotationData)
    });


  }
  ngOnInit() {


  }

  getAnnotationDetails(annot:any) {
    console.log("annot:" ,annot)
    annot.forEach(element => {
      if (element["title"] === ("Annotation: "+this.annotation)) {
        this.annotationData = element
        return
      }

    });

  }


  // tslint:disable-next-line:typedef
  showIF() {
    this.ifImageSource = 'assets/details/images/if.png';
  }
  // tslint:disable-next-line:typedef
  showThen() {
    this.thenImageSource = 'assets/details/images/then.png';
  }
  // tslint:disable-next-line:typedef
  showAnd() {
    this.thenImageSource = 'assets/details/images/and.png';
  }
  // tslint:disable-next-line:typedef
  showAndAny() {
    this.thenImageSource = 'assets/details/images/andany.png';
  }
  // tslint:disable-next-line:typedef
  showRedLine(event: any) {
    event.srcElement.classList.remove('clickedItem');
    setTimeout(() => {
      event.srcElement.classList.add('clickedItem');
    }, 0)
  }
  // tslint:disable-next-line:typedef
  showThenRedLine(event: any) {
    event.srcElement.classList.remove('redLinesOnAnnotationSet');
    setTimeout(() => {
      event.srcElement.classList.add('redLinesOnAnnotationSet');
    }, 0)
  }
}
