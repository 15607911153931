import { Component, Input, OnInit } from '@angular/core';
import { JsonfileService } from '../aggregated-rules/services/jsonfile.service';
@Component({
  selector: 'app-aggregated-rules-details-table',
  templateUrl: './aggregated-rules-details-table.component.html',
  styleUrls: ['./aggregated-rules-details-table.component.css']
})
export class AggregatedRulesDetailsTableComponent implements OnInit {
  headElements = [];
  @Input() annotation;
  constructor(public jsonfileServive: JsonfileService) { }
  selectedannotationchart;
  lables = new Array();
  values = new Array();
  ngOnInit(): void {
    
    
    
      //this.selectedannotationchart =this.annotation
      const chartdata = this.annotation["data"]
      console.log("chartdata :",chartdata)
      chartdata.forEach(element => {
        // console.log('elemnt', element);
        this.lables.push(element[0].toString());
        this.values.push(element[1].toString());
      });
 
  }



}
