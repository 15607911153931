import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AggregatedRulesDetailsComponent } from './aggregated-rules-details/aggregated-rules-details.component';
import { AggregatedRulesComponent } from './aggregated-rules/aggregated-rules.component';

const routes: Routes = [
  { path: 'aggregatedRules/:id', component: AggregatedRulesComponent },
  { path: 'aggregatedRulesDetails/:Annotation', component: AggregatedRulesDetailsComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
