<header>
    <mat-toolbar color="primary" style="background-color: rgb(255, 255, 255);">
        <mat-toolbar-row>




            <div
                style="display: block;
            width: 200px;
            height: 94px;
            margin-right: 0px;
            background: url(../assets/MajestEYE_icons/majesteye-icon-k-base-horizontal-white-background.svg) 100% 50%/auto 100% no-repeat">

            </div>
            <ul fxLayout="row" fxLayoutGap="70px" style="color: #0D2E4D; margin-left: 90px;">
                <li>

                    <button mat-icon-button>
                        <mat-icon>list</mat-icon>
                        Projects
                    </button>
                </li>
<!--
        <li>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <mat-icon>exit_to_app</mat-icon>
                            Sign out
                        </button>
                    </mat-menu>

                </li>
-->
            
            </ul>


            <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" style="color:#0D2E4D ;">
                <ul fxLayout="row" fxLayoutGap="20px">
                    <li>
                        <button mat-icon-button>
                            <mat-icon>settings</mat-icon>
                        </button>
                    </li>
                    <li>
                        <button mat-icon-button>
                            <mat-icon>help_outline</mat-icon>
                        </button>
                    </li>
                    <li>
                        <button mat-button [matMenuTriggerFor]="menu">
                            <mat-icon>person_outline</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item>
                                <mat-icon>exit_to_app</mat-icon>
                                Sign out
                            </button>
                        </mat-menu>

                    </li>
                </ul>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</header>
<mat-drawer-container>

    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
<app-footer></app-footer>