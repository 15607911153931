import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { core } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JsonfileService {
  //private location = '/assets/jsons/predict-rules.json';
  private chartDataLocation = 'http://41.226.183.241:2222/api/kbase/getCrossValidationData';
  public selectedAnnotation
public curentProjectId
  constructor(private http: HttpClient, public router: Router) {
    
  }


  //get data from core
  public getAggregatedRules(type, coreName, query): Observable<any> {
    console.log('Calling Service');
    let url = "http://41.226.183.241:2222/api/search/" + type + "/" + coreName + "/" + query
    let myResponse = this.http.get(url);
    console.log(url)
    return myResponse;
  }


  //get data from data.js
  public getChartData(projectId:string): Observable<any> {
    console.log('Load Annotation Data from Data.js');
    let params = {"projectId": projectId}
    let myResponse = this.http.post(this.chartDataLocation,params);
    return myResponse;
  }

  public navigateTo(route: any) {
    this.router.navigate([route]);
  }



}
