import { AfterViewInit } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JsonfileService } from './services/jsonfile.service';

@Component({
  selector: 'app-aggregated-rules',
  templateUrl: './aggregated-rules.component.html',
  styleUrls: ['./aggregated-rules.component.css']
})
export class AggregatedRulesComponent implements OnInit {
  id = "";
  
  
  selectedCore;
  isCompleted = true;
  selectedAnnotation!: Rule;
  displayedColumns = ['Annotation', 'Rules'];
  dataSource!: MatTableDataSource<Rule>;
  JsonFileObservable!: Observable<any>;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  // tslint:disable-next-line:variable-name
  constructor(private route: ActivatedRoute,private jsonfileServive: JsonfileService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
      this.jsonfileServive.curentProjectId=this.id
   });

   //console.log("Project : ",this.id)

    this.jsonfileServive.getAggregatedRules("kbase",this.id,"*:*").subscribe((rules: any) => {
      console.log('KbaseData : ', rules);
      this.isCompleted = false;
      this.dataSource = new MatTableDataSource(rules);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  ngOnInit() {
    
  }



  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  // tslint:disable-next-line:typedef
  onSelect(rule: Rule): void {
     //this.jsonfileServive.selectedAnnotation= JSON.stringify(this.selectedAnnotation);
     //alert( this.jsonfileServive.selectedAnnotation)
  
    this.selectedAnnotation = rule;
    //console.log(`selectedAnnotation = ${JSON.stringify(this.selectedAnnotation)}`);

    this.jsonfileServive.selectedAnnotation = JSON.parse(JSON.stringify(this.selectedAnnotation))

    this.router.navigate(['/aggregatedRulesDetails', this.selectedAnnotation["reference"]]);
    // alert((`selectedAnnotation = ${JSON.stringify(this.selectedAnnotation)}`));

  }
}
export interface Rule {
  Annotation: string;
  Rules: {
    Metrics: string[];
    Conditions: string[];
    Score: number;
  }[];
}

