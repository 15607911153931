

<h1>CrossValidation</h1>
<br>
  <div class="datatable">
<table mdbTable>
  <thead>
    <tr>
      <th  style="background-color: lightgray;" *ngFor="let head of lables" scope="col">{{head}} </th>
    </tr>
  </thead>
  <tbody>
    <tr mdbTableCol>
      <th *ngFor="let el of values" scope="col">{{el}} </th>
    </tr>
  </tbody>
</table>
</div>



