<mat-nav-list>
    <h2 matSubheader>Pages</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/aggregatedRules"><span class="material-icons">
            home
        </span>Home
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/"><span class="material-icons">
            emoji_objects
        </span>Solutions</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/"><span class="material-icons">
            article
        </span>Articles</a>

    <mat-divider></mat-divider>

    <h2 matSubheader>Tools</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/">
        <mat-icon>import_contacts</mat-icon>
        Contacts
    </a>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/">
        <mat-icon>contact_phone</mat-icon>
        Leads
    </a>


</mat-nav-list>