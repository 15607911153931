


<br><br><br>
  <div fxLayout fxLayoutAlign="center center">

   




     


    <mat-form-field fxFlex="40%">


   
      <input matInput type="text" (keyup)="doFilter($any($event.target).value)" placeholder="Filter">
    </mat-form-field>

  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isCompleted"></mat-progress-bar>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"  style="color: #0D2E4D;">

      <!-- Annotation Column -->
      <ng-container matColumnDef="Annotation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Annotations </th>
        <td mat-cell *matCellDef="let element" (click)="onSelect(element)"
          [class.selected]="element === selectedAnnotation"> {{element.reference}} </td>
      </ng-container>

      <!-- Rules Column -->
      <ng-container matColumnDef="Rules">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Rules Number </th>
        <td mat-cell *matCellDef="let element" (click)="onSelect(element)"
          [class.selected]="element === selectedAnnotation"> {{element.rules.length}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
