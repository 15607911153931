// Just To Test A Search Function
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  // tslint:disable-next-line:typedef
  transform(value: any, keys: string, term: string) {
    if (!term) {
      return value;
    }
    // tslint:disable-next-line:max-line-length
    //  return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
    return null;
  }

}
