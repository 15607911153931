import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js';
import * as _ from 'lodash';
import { JsonfileService } from '../aggregated-rules/services/jsonfile.service';
import { map } from 'rxjs/operators';
@Component({
    selector: 'app-aggregated-rules-charts',
    templateUrl: './aggregated-rules-charts.component.html',
    styleUrls: ['./aggregated-rules-charts.component.css']
})
export class AggregatedRulesChartsComponent implements OnInit {
    @Input() annotation;
    // tslint:disable-next-line:no-input-rename
    @Input('my-id') myId = '';
    // tslint:disable-next-line:no-input-rename
    @Input('type') type = 'bar';
    // tslint:disable-next-line:no-input-rename
    @Input('data') data;
    selectedannotationchart;
    lables = new Array();
    values = new Array();
    constructor(public jsonfileServive: JsonfileService) {
       
    }

    ngOnInit(): void {
        console.log("data:",this.data)
       
       
            //this.selectedannotationchart = selecteddata;
            //console.log('Data of the selected annotation', selecteddata);
            const chartdata = this.data["data"];
            console.log('Data to Display in The Chart', chartdata);
            chartdata.forEach(element => {
                // console.log('elemnt', element);
                this.lables.push(element[0].toString());
                this.values.push(element[1].toString());
            });
            this.values.shift(); // Skip First Element
            this.lables.shift();
            console.log('Lables', this.lables);
            console.log('Values', this.values);
            //this.drawCharts();
        }
    

    drawCharts() {
            var myChart = new Chart(this.myId, {
                type: this.type,
                data: {
                    // tslint:disable-next-line:object-literal-shorthand
                    labels: this.lables,
                    datasets: [{
                        label: 'Metrics',
                        data: this.values,
                        backgroundColor: [
                            'rgba(50, 60, 255, 0.4)',
                            'rgba(20,200,10,0.4)',
                            'rgba(255, 255, 0, 0.4)',
                            'rgba(106, 90, 205, 0.4)',
                            'rgba(153, 102, 255, 0.4)',
                            'rgba(255, 159, 64, 0.4)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    title: {
                        display: true,
                        //text: 'Line Chart'
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                        }],
                        yAxes: [{
                            display: true,
                            //type: 'logarithmic',
                        }]
                    }
                }
            });
        }
        // tslint:disable-next-line:typedef
    ngAfterViewInit() {
        this.drawCharts();
    }
}
