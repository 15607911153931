


  <title style="color: #0D2E4D;">  {{annotation}}</title>


<div class="namespace-arba" >
  <main class="arba" property="schema:mainEntity" resource="" typeof="" id="content"
    (click)="ifImageSource = 'assets/details/images/if.png'; showIF(); thenImageSource = 'assets/details/images/then.png'; showThen()">
    <section id="page-header">
      &nbsp;
      <h4 class="page-title" style="color: #0D2E4D;">{{annotation}}</h4>
    </section>
    <div id="unirulePage" class="unirulePage main">
      <section id="conditionsContainer" style="color: #0D2E4D;">
        <h3 style="color: #0D2E4D;">If a customer meets these conditions...

        </h3><span style="display:initial;" id="theIfIcon">
          <img [src]="ifImageSource" /></span>
        <div id="conditionsSet">
          <h4 style="color: #0D2E4D;">Common conditions ... </h4>
          <ul id="commonConditions">
            <li id="main_con" *ngFor="let rule of selectedAnnotation.rules ; let i=index">
              <ul>
                <li *ngFor="let item of rule.antecedent" style="color: #0D2E4D;">Matches condition signature: <a href="#">{{item}}</a>

                </li>
                <img *ngIf=" i < (selectedAnnotation.rules.length -1 )" src="assets/details/images/or.png"
                  id="orImage" />
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section id="annotationsContainer">
        <h3 style="color: #0D2E4D;">... then these annotations are applied

        </h3>
        <section><span style="display:initial;" id="thenIcon"><img [src]="thenImageSource" /></span></section>
        <span style="display:none" id="andIcon"><img src="assets/details/images/and.png" /></span><span
          style="display:none;" id="andAnyIcon"><img src="assets/details/images/andany.png" /></span>
        <div class="redLinesOff redLinesOnAnnotationSet" id="annotationsSet">
          <ul>
            <li class="main_ann">
              <ul class="catAnnotations">
                <li class="catAnnotation bulletPointList">
                  <div class="equationHolder"></div>
                  <span class="context-help tooltipped-click html">
                    <a class="noBorder">Annotation:
                      {{annotation}}</a>

                    <span style="display:none">
                      <span class="tooltipped">
                      </span></span>
                  </span>

                  <div *ngFor="let element of rule ; let i=index">Score {{i+1}}: <span
                      class="context-help tooltipped-click html"><a class="noBorder"> {{element.Score}}</a><span
                        style="display:none"><span class="tooltipped">
                        </span></span>
                    </span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </main>
  <hr>
  <div>



    <app-aggregated-rules-details-table *ngIf="annotationData !=null" [annotation]="annotationData">

    </app-aggregated-rules-details-table>


  </div>



  <br><br>
  <div>






   <app-aggregated-rules-charts my-id="chart1" *ngIf="annotationData" [data]="annotationData" [annotation]="annotation" type="radar">
    </app-aggregated-rules-charts>

    <app-aggregated-rules-charts my-id="chart3" *ngIf="annotationData" [data]="annotationData" [annotation]="annotation" type="bar">
    </app-aggregated-rules-charts>




  </div>
  <!-- <footer id="page-footer">
    <section>
      <h5>Tools</h5>
      <ul>
        <li><a href="#">BLAST</a></li>
        <li><a href="#">Align</a></li>
        <li><a href="#">Retrieve/ID mapping</a></li>
        <li><a href="#">Peptide search</a></li>
      </ul>
    </section>
    <section>
      <h5>Core data</h5>
      <ul>
        <li><a href="#">Protein knowledgebase (UniProtKB)</a></li>
        <li><a href="#">Sequence clusters (UniRef)</a></li>
        <li><a href="#">Sequence archive (UniParc)</a></li>
        <li><a href="#">Proteomes</a></li>
      </ul>
    </section>
    <section>
      <h5>Supporting data</h5>
      <ul>
        <li><a href="#">Literature citations</a></li>
        <li><a href="#">Taxonomy</a></li>
        <li><a href="#">Keywords</a></li>
        <li><a href="#">Subcellular locations</a></li>
        <li><a href="#">Cross-referenced databases</a></li>
        <li><a href="#">Diseases</a></li>
      </ul>
    </section>
    <section>
      <h5>Information</h5>
      <ul>
        <li><a href="#">About UniProt</a></li>
        <li><a href="#">Help</a></li>
        <li><a href="#">FAQ</a></li>
        <li><a href="#">UniProtKB manual</a></li>
        <li><a href="#">Technical corner</a></li>
        <li><a href="#">Expert biocuration</a></li>
      </ul>
    </section>
  </footer> -->
</div>

